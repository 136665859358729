import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { Edit, Search, Back } from '@element-plus/icons-vue';
import SearchSelect from '../../components/course/Search';
import { courseSearchList } from '../../assets/js/data';
import CommonTable from '../../components/common/CommonTable';
import { structureOperation, sendThis } from '../../data/course/courseData';
import { structureEdit } from '../../data/courseTable';
import { structureDrawer } from '../../data/courseDrawer';
import CommonDrawer from '../../components/common/CommonDrawer';
import navigation from '../../components/common/Navigation';
import { qn_token_get, uploadFile } from '../../api/qiniuApI';
import CopyStruture from '../../components/popup/CopyStruture'; //同步
import FZStruture from '../../components/popup/FZStruture'; //复制
import RelateStruture from '../../components/popup/RelateStruture'; //关联

import Hint from '../../components/common/Hint.vue';
import AddResource from '../../components/popup/AddResource';
//图标
import EditLevelStructure from '../../components/popup/EditLevelStructure';
import * as qiniu from 'qiniu-js';
import { activitytypeGetAll, tagGetAll } from '../../api/mApi';
import TagPopup from '../../components/tag/TagPopup.vue'; //引入七牛

export default {
  name: "CourseStructure",
  data: function data() {
    return {
      initTagList: [],
      //初始化的tag
      currentActivity: "",
      //当前活动
      tagList: [],
      //标签列表
      targetTypeDataList: [],
      //
      isFZ: 1,
      //是否显示复制层级
      parentListData: {},
      //隶属上级的数据
      currentLeftData: null,
      //左侧当前选中的数据
      localCourseData: JSON.parse(localStorage.getItem("course")),
      //当前课程额数据
      relateCurrentRow: null,
      //关联当前层级
      copyCurrentRow: null,
      //同步当前层级
      fZCurrentRow: null,
      //复制当前层级
      navData: [{
        //面包屑
        meta: {
          title: "课程"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程中心"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程管理"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "结构管理"
        },
        path: "/course/structure"
      }, {
        meta: {
          title: "层级管理"
        }
      }],
      barStructure: 0,
      //侧边栏选择第几个
      Back: Back,
      //返回按钮
      definitionId: parseInt(this.$route.query.definitionId) || 2,
      definitionName: this.$route.query.definitionName || "阶段",
      barList: {
        list: []
      },
      //侧边栏列表
      isEdit: false,
      //默认是添加
      drawerKey: 0,
      //抽屉key
      structureDrawer: structureDrawer,
      structureOperation: structureOperation,
      structureEdit: structureEdit,
      structureDrop: [],
      //下拉结构
      structurelevelDrop: [],
      //标准下拉结构
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      //获取用户数据
      tableKey: 1,
      //避免数据更新表格视图不更新
      tableData: [],
      total: 0,
      //页数总数
      currentPage: parseInt(this.$route.query.currentPage ? this.$route.query.currentPage : 1),
      // 当前页数
      pageSize: parseInt(this.$route.query.pageSize ? this.$route.query.pageSize : 10),
      //每页个数
      courseSearchList: courseSearchList,
      courseData: JSON.parse(localStorage.getItem("course")),
      state: true,
      //课程状态
      Edit: Edit,
      //图标
      imageUrl: "",
      //课程图标
      resetData: null,
      //结构数据的初始值 永远不变，用来还原用的
      filter: {
        //上面筛选的数组
        list: []
      },
      currentFilterJson: {
        valueJson: {}
      },
      //当前选中的筛选数据参数
      structureData: {
        //添加结构数据
        acttypeId: "",
        //活动分类id
        name: "",
        //阶段
        courseId: JSON.parse(localStorage.getItem("course")).id,
        //课程ID
        courseName: JSON.parse(localStorage.getItem("course")).name,
        //课程ID
        definitionId: parseInt(this.$route.query.definitionId) || 2,
        definitionName: this.$route.query.definitionName || "阶段",
        logo: "",
        //图片
        pid: "",
        //父级ID
        haveResource: 0,
        //本层结构是否带课程资源：0无 1有
        description: "",
        //描述
        haveFree: 0,
        //是否免费
        recommend: 0,
        //是否推荐
        rank: 1,
        //排列顺序
        filterText: "",
        //排列顺序
        state: 1 //状态：1正常 -1禁用
      } //添加结构、修改结构的数据
    };
  },

  watch: {
    '$route': 'initData'
  },
  components: {
    TagPopup: TagPopup,
    SearchSelect: SearchSelect,
    CommonTable: CommonTable,
    CommonDrawer: CommonDrawer,
    navigation: navigation,
    CopyStruture: CopyStruture,
    FZStruture: FZStruture,
    RelateStruture: RelateStruture
  },
  mounted: function mounted() {
    //发送当前实例
    sendThis(this);
    //设置面包屑
    this.$emit('navigation', this.navData);
  },
  computed: {
    //判断当前是第几层
    checkStructure: function checkStructure() {
      if (!this.structureDrop || this.structureDrop.length == 0) return -1;
      for (var i = 0; i < this.structureDrop.length; i++) {
        if (this.$route.query.definitionId == this.structureDrop[i].id) {
          return i;
        }
      }
      return -1;
    }
  },
  created: function created() {
    //获取七牛token
    this.getToken();
    //拿到初始值
    this.resetData = _objectSpread({}, this.structureData);
    //获取课程结构
    this.getStructuredefinitionDrop();
    this.initData();

    //获取活动分类
    this.activitytypeGetAll();

    //获取维度分类
    this.getAllFun();
  },
  methods: {
    //添加标签、编辑标签
    tag: function tag(row) {
      this.tagList = this.initTagList;
      this.currentActivity = row;
      this.$refs.tagPopup.showPopup(row);
    },
    setTagList: function setTagList(data) {
      this.tagList = data;
      this.$refs.tagPopup.setFilterTagist(data);
    },
    //获取某一维度下的所有标签
    getTagAll: function getTagAll() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'tagGetAll',
        data: {
          tagtypeId: this.targetTypeDataList[0].id
        },
        sucFn: function sucFn(res) {
          _this2.tagList = res.data;
          _this2.initTagList = res.data;
        }
      });
    },
    //获取所有维度
    getAllFun: function getAllFun() {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: 'tagtypeGetAll',
        data: {},
        sucFn: function sucFn(res) {
          _this3.targetTypeDataList = res.data;
          _this3.getTagAll();
        }
      });
    },
    //获取所有活动分类
    activitytypeGetAll: function activitytypeGetAll() {
      var _this4 = this;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId
      };
      this.service.axiosUtils({
        requestName: 'activitytypeGetAll',
        data: postData,
        sucFn: function sucFn(res) {
          _this4.structureDrawer.list[3].drop.list = res.data;
          /*  this.filter.list[index].list=res.data;
            if(res.data.length == 1 && index == 0){
              this.filter.list[index].value=res.data[0].id;
              this.filter.list[index].isShow=true;
              //设置全局
              this.currentFilterJson.valueJson=res.data[0];
              this.currentFilterJson.index=0;
            }*/
          /*          //是否需要加载表格资源
                    json.sucFn && json.sucFn(res,this.filter.list[index].value);*/
        }
      });
    },
    //显示复制层级弹框
    showFZDrawer: function showFZDrawer() {
      this.fzFn();
    },
    //刷新当前页面
    reload: function reload() {
      window.location.reload();
    },
    //获取和筛选对应的层级
    getFilterV: function getFilterV(data, value) {
      if (!data.list) return {};
      for (var i = 0; i < data.list.length; i++) {
        if (value == data.list[i].id) {
          return data.list[i];
        }
      }
      return {};
    },
    //选择筛选的值有变化的时候
    clickChangeFilter: function clickChangeFilter(v, k) {
      //初始化页码
      this.currentPage = 1;
      /*清空后面子级的下拉和选中的值*/
      for (var i = k + 1; i < this.filter.list.length; i++) {
        this.filter.list[i].list = [];
        this.filter.list[i].value = "";
      }
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10,
          definitionId: this.structureData.definitionId,
          definitionName: this.structureData.definitionName
        }
      });
      var currentJson = this.getFilterV(v, v.value);
      var _this = this;
      this.currentFilterJson.valueJson = currentJson;
      this.currentFilterJson.index = k;

      //如果不是最后一层的上一层，就获取筛选框
      if (k + 1 < this.filter.list.length - 1) {
        // if(this.checkStructureN() == (k+1)){
        this.structureGetChildren({
          index: k + 1,
          selectedId: v.value,
          sucFn: function sucFn(res, selecetedId) {
            _this.initData();
          }
        });
      } else {
        this.initData();
      }
      /* {
         index:k+1,
           selectedId:v.value,
         sucFn(res,selecetedId){
         initData({
           filterDefinitionId:v.value,
           filterStructureId:'filterStructureId'
         })
       }
       }*/
    },
    //获取课程子级（下拉的筛选）
    structureGetChildren: function structureGetChildren(json) {
      var _this5 = this;
      var post = {
        courseId: JSON.parse(localStorage.getItem("course")).id
      };
      post.selectedId = json.selectedId;
      var index = json.index || 0;
      this.service.axiosUtils({
        requestName: 'structureGetChildren',
        data: post,
        sucFn: function sucFn(res) {
          _this5.filter.list[index].list = res.data;
          if (res.data.length == 1 && index == 0) {
            _this5.filter.list[index].value = res.data[0].id;
            _this5.filter.list[index].isShow = true;
            //设置全局
            _this5.currentFilterJson.valueJson = res.data[0];
            _this5.currentFilterJson.index = 0;
          }
          // console.log("this.filter.list11",this.filter.list, this.currentFilterJson)
          //是否需要加载表格资源
          json.sucFn && json.sucFn(res, _this5.filter.list[index].value);
        }
      });
    },
    //判断当前是第几层
    checkStructureN: function checkStructureN(id) {
      var aId = id || this.$route.query.definitionId;
      for (var i = 0; i < this.structureDrop.length; i++) {
        if (aId == this.structureDrop[i].id) {
          return i;
        }
      }
      return -1;
    },
    //同步层级
    copy: function copy(row) {
      this.copyCurrentRow = row;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        courseId: this.courseData.id,
        definitionId: this.definitionId
      };
      if (this.currentFilterJson.valueJson && this.currentFilterJson.valueJson.definitionId != this.definitionId) {
        /***********/
        postData.filterDefinitionId = this.currentFilterJson.valueJson.definitionId;
        postData.filterStructureId = this.currentFilterJson.valueJson.id;
      }
      this.$refs.copyStruture.showDrawer(postData, row);
      /* this.service.axiosUtils({
         requestName: 'copyCourseStructure',
         data:row,
         sucFn: res => {
           if(res.status == 1){
             this.centerDialogVisible=false;
             this.$toast.success(res.message)
             this.initData()
           }else{
             this.$toast.error(res.message)
           }
          }
         }
       )*/
    },
    //复制层级
    fzFn: function fzFn(row) {
      var _this6 = this;
      this.fZCurrentRow = row || {};
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        courseId: this.courseData.id,
        definitionId: this.definitionId
      };
      if (this.currentFilterJson.valueJson && this.currentFilterJson.valueJson.definitionId != this.definitionId) {
        /***********/
        postData.filterDefinitionId = this.currentFilterJson.valueJson.definitionId;
        postData.filterStructureId = this.currentFilterJson.valueJson.id;
      }
      this.$refs.fZStruture.showDrawer(postData, row);
      setTimeout(function () {
        _this6.$nextTick(function () {
          if (_this6.currentFilterJson && _this6.currentFilterJson.valueJson && _this6.currentFilterJson.index == _this6.checkStructureN() - 1) {
            _this6.$refs.fZStruture.setStructureId(_this6.currentFilterJson.valueJson.id);
          } else {
            _this6.$refs.fZStruture.setStructureId("");
          }
        });
      }, 30);
    },
    //关联层级
    relate: function relate(row) {
      this.relateCurrentRow = row;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        courseId: this.courseData.id,
        definitionId: this.definitionId
      };
      if (this.currentFilterJson.valueJson && this.currentFilterJson.valueJson.definitionId != this.definitionId) {
        /***********/
        postData.filterDefinitionId = this.currentFilterJson.valueJson.definitionId;
        postData.filterStructureId = this.currentFilterJson.valueJson.id;
      }
      this.$refs.relateStruture.showDrawer(postData, row);
    },
    //传图片
    changeFile: function changeFile(file) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        // console.log(e)
        _this.structureData.logo = this.result;
      };
    },
    //获取token
    getToken: function getToken() {
      var _this7 = this;
      qn_token_get().then(function (res) {
        _this7.tokenData = res;
      });
    },
    //切换层级
    changePage: function changePage(v, k) {
      // this.activityId = v.id;
      this.structureData.definitionId = v.id;
      this.structureData.definitionName = v.name;

      //重新渲染筛选
      // setTimeout(()=>{
      //   this.filter.list.length
      for (var i = k; i < this.filter.list.length; i++) {
        if (i > 0) {
          this.filter.list[i].isShow = false;
        }
      }
      for (var _i = 0; _i < k; _i++) {
        this.filter.list[_i].isShow = true;
        var json = this.filter.list[_i];

        //更新总筛选字段
        if (json.value != "" && json.value != "undefined" && json.value != undefined) {
          var currentJson = this.getFilterV(this.filter.list[_i], this.filter.list[_i].value);
          this.currentFilterJson.valueJson = currentJson;
          this.currentFilterJson.index = _i;
        }
      }

      //更新组件视图
      // this.changeViewFilter(k);

      //更新路由
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10,
          definitionId: this.structureData.definitionId,
          definitionName: this.structureData.definitionName
        }
      });

      // },0);
    },
    resetDataFn: function resetDataFn() {
      // this.isEdit=false;
      //弹框的数据初始化
      this.structureData = {
        //添加结构数据
        name: "",
        //阶段
        courseId: JSON.parse(localStorage.getItem("course")).id,
        //课程ID
        courseName: JSON.parse(localStorage.getItem("course")).name,
        //课程ID
        definitionId: parseInt(this.$route.query.definitionId) || 2,
        definitionName: this.$route.query.definitionName || "阶段",
        logo: "",
        //图片
        pid: "",
        //父级ID
        haveResource: 0,
        //本层结构是否带课程资源：0无 1有
        description: "",
        //描述
        haveFree: 0,
        //是否免费
        recommend: 0,
        //是否推荐
        rank: 1,
        //排列顺序
        state: 1 //状态：1正常 -1禁用
      };
    },
    //确认弹框的内容
    dialogContent: function dialogContent(json, row) {
      if (json.type == 1) {
        json.content = "确定删除" + row.name + "吗？";
      } else {
        json.content = "确定要同步《" + row.name + "》吗？";
        // json.content="确定要复制《"+row.name+"》到《"+row.parent.name+"》吗？";
      }

      return json;
    },
    //删除结构
    deleteFn: function deleteFn(row) {
      var _this8 = this;
      this.service.axiosUtils({
        requestName: 'deleteCourseStructure',
        data: {
          id: row.id
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this8.centerDialogVisible = false;
            _this8.$toast.success(res.message);
            _this8.initData();
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    },
    //编辑结构
    edit: function edit(row) {
      var _this9 = this;
      // this.isEdit=true;
      this.showAddCourseDrawer(false);
      this.resetDataFn();
      setTimeout(function () {
        //然后在赋值
        _this9.structureData = _objectSpread({}, row);
        _this9.structureData.courseName = _this9.courseData.name;
        _this9.structureData.definitionName = _this9.definitionName;
        _this9.structureData.pid = row.parent ? row.parent.id : 0;
        _this9.structureData.roleId = _this9.Ls.getParseToken('nof_userInfo').roleId;
        _this9.structureData.filterText = _this9.filterText;
      }, 30);
    },
    //显示添加课程结构定义抽屉弹框
    showAddCourseDrawer: function showAddCourseDrawer(isAdd) {
      var _this10 = this;
      if (isAdd) {
        setTimeout(function () {
          //新建
          if (_this10.currentFilterJson && _this10.currentFilterJson.valueJson && _this10.currentFilterJson.index == _this10.checkStructureN() - 1) {
            _this10.structureData.pid = _this10.currentFilterJson.valueJson.id;
          }
        }, 30);
        this.isEdit = false;
      } else {
        //编辑

        this.isEdit = true;
      }
      //设置筛选字段
      this.setDrawerFilter();
      //显示抽屉弹框
      this.$refs.commonDrawer.drawer = true;

      //获取课程标准定义结构
      // if(this.structurelevelDrop.length == 0){
      //   this.getStandardlevelDrop();
      // }
    },
    /*    handleAvatarSuccess:(response, uploadFile) => {
          this.imageUrl = URL.createObjectURL(uploadFile.raw)
        },*/
    //获取左边的侧边栏
    getStructuredefinitionDrop: function getStructuredefinitionDrop() {
      var _this11 = this;
      this.service.axiosUtils({
        requestName: 'getStructuredefinitionDrop',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          courseId: this.courseData.id,
          definitionId: this.definitionId
        },
        sucFn: function sucFn(res) {
          _this11.structureDrop = res.data;
          var checkIndex = _this11.checkStructureN();
          _this11.currentLeftData = _this11.structureDrop[checkIndex];
          //判断当前层级是否显示同步 层级不固定了，注释了吧
          //判断同步按钮是否显示
          if (checkIndex < 1) {
            _this11.structureEdit.list[6].buttonList[1].isHidden = true;
          } else {
            _this11.structureEdit.list[6].buttonList[1].isHidden = false;
          }
          //判断设置标签按钮是否显示
          if (_this11.currentLeftData.stdlevelId == 4) {
            _this11.structureEdit.list[6].buttonList[0].isHidden = false;
          } else {
            _this11.structureEdit.list[6].buttonList[0].isHidden = true;
          }

          //循环该出现几层
          for (var i = 0; i < _this11.structureDrop.length; i++) {
            var json = {
              placeholder: "请选择" + _this11.structureDrop[i].name
            };
            //

            if (_this11.checkStructureN(_this11.structureDrop[i].id) < checkIndex) {
              //this.filter.list.push(1)
              json.isShow = true;
            } else {
              json.isShow = false;
              //this.filter.list.push(2)
            }

            _this11.filter.list.push(json);
          }

          // console.log("currentLeftData",this.currentLeftData)

          /*判断是否需要出现下拉筛选*/
          _this11.changeViewFilter(checkIndex);
          // this.drawerKey++;
        }
      });
    },
    //初始化获取两个
    //改变筛选框渲染
    changeViewFilter: function changeViewFilter(checkIndex, isTable) {
      var checkIndex = checkIndex || this.checkStructureN();
      var _this = this;
      this.structureGetChildren({
        checkIndex: checkIndex,
        sucFn: function sucFn(res, id) {
          // if(checkIndex >= 2){
          _this.structureGetChildren({
            selectedId: res.data[0].id,
            index: 1
          });
          // }
        }
      });
    },
    //获取层级的隶属上级
    getParent: function getParent() {
      var _this12 = this;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        courseId: this.courseData.id,
        definitionId: this.definitionId
      };
      //课程不触发
      if (this.currentFilterJson.valueJson && this.currentFilterJson.valueJson.definitionId != this.definitionId) {
        /***********/
        postData.filterDefinitionId = this.currentFilterJson.valueJson.definitionId;
        postData.filterStructureId = this.currentFilterJson.valueJson.id;
        /*************/
        //判断当前选中的筛选层级是否 是当前层级的父级
        /*  if((this.currentFilterJson.index > this.checkStructureN()-2) && this.this.checkStructureN() >=2){
            postData.filterDefinitionId=this.filter.list[this.currentFilterJson.index-1].definitionId;
            postData.filterStructureId= this.filter.list[this.currentFilterJson.index-1].id;
          }else{
            postData.filterDefinitionId=this.currentFilterJson.valueJson.definitionId;
            postData.filterStructureId=this.currentFilterJson.valueJson.id;
          }*/

        /* let str="";
         for (let i = 0; i < this.filter.list.length; i++) {
           if(this.filter.list[i].isShow && this.filter.list[i].value != undefined && this.filter.list[i].value != "" && this.filter.list[i].value != "undefined" && i<this.checkStructureN()-1){
             let currentJson=this.getFilterV(this.filter.list[i],this.filter.list[i].value)
             if(i == 0){
               str=currentJson.name
             }else{
               str=str+"/"+currentJson.name
              }
           }
         }
         this.filterText=str;
         this.structureData.filterText=str;*/
      }

      this.service.axiosUtils({
        requestName: 'coursestructureGetParent',
        data: postData,
        sucFn: function sucFn(res) {
          // this.structureParentDrop=res.data;
          var resData = res.data;
          if (resData.length > 0 && resData[0].pName) {
            for (var i = 0; i < resData.length; i++) {
              resData[i].name = resData[i].name + "-" + resData[i].pName;
            }
          }
          _this12.structureDrawer.list[5].drop.list = res.data;
          _this12.structureDrawer.list[5].drop.list.push({
            id: 0,
            name: "无",
            value: 0
          });

          //赋值隶属上级的数据
          _this12.parentListData.list = _this12.structureDrawer.list[5].drop.list;
          //获取筛选字段
          // this.structureData.filterText=this.filter.list[0].value
        }
      });
    },
    //设置抽屉展示的筛选字段
    setDrawerFilter: function setDrawerFilter() {
      var str = "";
      for (var i = 0; i < this.filter.list.length; i++) {
        if (this.filter.list[i].isShow && this.filter.list[i].value != undefined && this.filter.list[i].value != "" && this.filter.list[i].value != "undefined" && i < this.checkStructureN() - 1) {
          var currentJson = this.getFilterV(this.filter.list[i], this.filter.list[i].value);
          if (i == 0) {
            str = currentJson.name;
          } else {
            str = str + "/" + currentJson.name;
          }
        }
      }
      this.filterText = str;
      this.structureData.filterText = str;
    },
    //获取标准课程结构下拉
    /*    getStandardlevelDrop(){
          this.service.axiosUtils({
            requestName: 'getStandardlevelDrop',
            data: {
              userId:this.userInfo.userId,
              roleId:this.userInfo.roleId
            },
            sucFn: res => {
              this.structurelevelDrop=res.data;
              this.courseStructureDrawer.list[2].drop.list=this.structurelevelDrop;
              // this.drawerKey++;
            }
          })
        },*/
    //过滤表格的是否有资源
    formatter: function formatter(row, column) {
      var isHava = "否";
      if (row.haveResource == 0) {
        isHava = "否";
      } else {
        isHava = "是";
      }
      return isHava;
    },
    nextPage: function nextPage(t) {
      switch (t) {
        case 1:
          this.$router.push({
            path: "/course/edit/structure"
          });
          break;
      }
    },
    //返回课程结构
    returnBack: function returnBack() {
      this.$router.replace({
        path: '/course/structure'
      });
    },
    //修改状态
    changeState: function changeState(value) {
      var _this13 = this;
      this.courseData.roleId = this.userInfo.roleId;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        id: value.id
      };
      if (value.state == 1) {
        postData.state = -1;
      } else {
        postData.state = 1;
      }
      this.service.axiosUtils({
        requestName: 'structureChangeState',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this13.$toast.success(res.message);
          } else {
            _this13.$toast.error(res.message);
          }
          _this13.initData();
        }
      });
    },
    //获取展示的最后一个有value的数据
    getFilterShowJson: function getFilterShowJson() {
      // this.currentFilterJson.valueJson=this.getFilterV(this.filter.list[k-1],this.filter.list[k-1].value)
      // this.currentFilterJson.index=k-1;
      for (var i = 0; i < this.filter.list.length; i++) {
        var json = this.filter.list[i];
        if (json.isShow && json.value != "" && json.value != "undefined" && json.value != undefined) {
          var currentJson = this.getFilterV(this.filter.list[i], this.filter.list[i].value);
          this.currentFilterJson.valueJson = currentJson;
          this.currentFilterJson.index = i;
        }
      }
    },
    //搜索数据
    searchSonFun: function searchSonFun() {
      this.initData();
    },
    //初始化获取数据
    initData: function initData(json) {
      var _this14 = this;
      //如果是销毁，就不触发
      if (this.isBbeforeDestroy) {
        return;
      }
      var json = json || {};
      //设置面包屑
      this.$emit('navigation', this.navData);
      this.definitionId = parseInt(this.$route.query.definitionId) || 2;
      var postData = {
        definitionId: this.definitionId,
        courseId: this.courseData.id,
        pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10,
        pageIndex: this.$route.query.currentPage ? this.$route.query.currentPage : 1
      };
      /*  if(this.currentPage){
          postData.pageIndex=this.currentPage;
        }*/

      //如果有全局的筛选字段，并且筛选字段是当前层级的前几级的话就赋予字段
      // alert(JSON.stringify(this.currentFilterJson))
      if (this.currentFilterJson.valueJson && this.checkStructureN() > this.currentFilterJson.index) {
        postData.filterDefinitionId = this.currentFilterJson.valueJson.definitionId;
        postData.filterStructureId = this.currentFilterJson.valueJson.id;
        // this.clickChangeFilter(this.currentFilterJson.valueJson);
      }

      //搜索的关键字
      if (this.currentFilterJson.valueJson.structureName) {
        postData.structureName = this.currentFilterJson.valueJson.structureName;
      }

      //如果筛选有值就加上筛选字段
      this.service.axiosUtils({
        requestName: 'courseStructureGetList',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            var checkIndex = _this14.checkStructureN();
            _this14.currentLeftData = _this14.structureDrop[checkIndex];
            // console.log("currentLeftData",this.currentLeftData)

            _this14.tableData = res.data.data;
            for (var i = 0; i < _this14.tableData.length; i++) {
              var tableItem = _this14.tableData[i];
              _this14.tableData[i].allName = tableItem.definition.definitionName;
              // this.tableData[i].allName=tableItem.parent.name+"-"+tableItem.definition.definitionName;
            }

            _this14.total = res.data.total;
            _this14.tableKey++;

            //判断当前层级是否显示同步 层级不固定了，注释了吧
            if (_this14.checkStructureN() < 1) {
              _this14.structureEdit.list[6].buttonList[1].isHidden = true;
            } else {
              _this14.structureEdit.list[6].buttonList[1].isHidden = false;
            }
            //判断设置标签按钮是否显示
            if (_this14.currentLeftData.stdlevelId == 4) {
              _this14.structureEdit.list[6].buttonList[0].isHidden = false;
            } else {
              _this14.structureEdit.list[6].buttonList[0].isHidden = true;
            }
          } else {
            _this14.$toast.error(res.message);
          }
        }
      });

      //获取课程结构（隶属上级）
      this.getParent();
    },
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      // 获取最大页数
      var pageMax = Math.ceil(this.total / this.pageSize);
      // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
      if (this.currentPage > pageMax) {
        this.currentPage = pageMax;
      }
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: page
        }
      });
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10
        }
      });
    },
    //添加课程结构定义确定
    confirmClick: function confirmClick(valid, formRef, formData) {
      // console.log(JSON.stringify(this.structureData));
      var _this = this;
      // console.log(this.file)
      if (this.file) {
        uploadFile({
          folderName: "logo",
          complete: function complete(res, url) {
            _this.structureData.logo = url;
            _this.addEditCourseStructure(valid, formRef, formData);
          }
        }, this.file);
      } else {
        _this.addEditCourseStructure(valid, formRef, formData);
      }
    },
    //添加修改
    addEditCourseStructure: function addEditCourseStructure(valid, formRef, formData) {
      var _this15 = this;
      if (valid) {
        var requestName = "";
        if (this.isEdit) {
          requestName = "editCourseStructure";
        } else {
          //添加
          requestName = "addCourseStructure";
        }
        this.service.axiosUtils({
          requestName: requestName,
          data: this.structureData,
          sucFn: function sucFn(res) {
            if (res.status == 1) {
              _this15.file = "";
              _this15.drawer = false;
              _this15.$toast.success(res.message);
              _this15.$refs.commonDrawer.drawer = false;
              formRef.resetFields();
              //重新拉取列表
              _this15.initData();
            } else {
              _this15.$toast.error(res.message);
            }
          }
        });
      }
    }
  },
  beforeRouteLeave: function beforeRouteLeave() {
    this.isBbeforeDestroy = true;
  }
};